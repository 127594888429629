<template>
    <footer class="main-footer">
        <div class="columns is-justify-content-space-between is-align-items-center">
            <div class="column">
                <h2 class="column__title">Enlaces útiles</h2>
                <p class="column__txt"><a href="#">Inicio</a></p>
                <p class="column__txt"><a href="#">Ayuda</a></p>
            </div>
            <div class="column">
                <h2 class="column__title">Legal</h2>
                <p class="column__txt"><a href="#">Términos y Condiciones</a></p>
                <p class="column__txt"><a href="#">Política de Privacidad</a></p>
            </div>
            <div class="column">
                <img :src="require('@/assets/images/branding/logotipo.png')" alt="Isologo" title="Isologo"
                    style="display:block; padding-top: 30px; width: 250px; height: auto; margin: auto;">
            </div>
        </div>
        <p class="copy">
            &copy;{{ anio }} Desarrollado por <a href="#" target="_blank">Grupo Verfrut</a>
        </p>
    </footer>
</template>

<script>
import moment from 'moment';

export default {
    name: 'CustomFooter',
    data() {
        return {
            anio: moment().year()
        }
    }
};
</script>

<style>
    .main-footer {
        background: #1e1e21;
        color: white;
        padding: 40px 20px;
        font-size: 0.8em;
    }
    .main-footer a {
        color: white;
        text-decoration: none;
        font-weight: bold;
    }
    .copy {
        text-align: center;
        margin: 50px auto auto;
    }
    .main-footer [class*="icon-"] {
        color: white;
        text-decoration: none;
    }
    .main-footer [class*="icon-"]::before {
        position: relative;
        top: 3px;
        right: 5px;
    }

    @media screen and (min-width: 1024px) {
        .main-footer {
            padding: 70px 30px;
        }
    }
</style>
